import { DESKTOP_LINKS, MOBILE_NAVIGATION } from '../../lib/navigation'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'

export const FOOD_AND_WELLNESS_ENTRY = {
  label: 'Food & Wellness',
  url: '/food-and-wellness',
}

const getFoodAndWellnessConfig = () => {
  const { isEnabled } = getConfigFor(SPLIT_FEATURES.FOOD_WELLNESS_ENTRY_POINT)
  return {
    isFoodAndWellnessEnabled: isEnabled,
  }
}

export const getDesktopNavigationList = () => {
  const { isFoodAndWellnessEnabled } = getFoodAndWellnessConfig()

  const navigationList = DESKTOP_LINKS || []

  if (isFoodAndWellnessEnabled) {
    return [
      ...navigationList.slice(0, -1),
      FOOD_AND_WELLNESS_ENTRY,
      ...navigationList.slice(-1),
    ]
  }

  return navigationList
}

export const getMobilePrimaryNavigation = () => {
  const { isFoodAndWellnessEnabled } = getFoodAndWellnessConfig()

  const primaryNavigation = MOBILE_NAVIGATION.navPrimary || []

  if (isFoodAndWellnessEnabled) {
    return [...primaryNavigation, FOOD_AND_WELLNESS_ENTRY]
  }

  return primaryNavigation
}
