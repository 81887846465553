import React, { Component } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Text from '../Text/Text'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../lib/amplitude'
import { getCtaLocationMeta } from '../../lib/utils'

const MegaMenuContainer = styled.div`
  height: calc(100vh - 162px);
  display: flex;
`
const L1Container = styled.div`
  height: calc(100vh - 162px);
  overflow-y: auto;
  width: 14.625rem;
  background-color: #f3f5f7;
`
const MegaMenuDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-content: start;
  flex-wrap: wrap;
  height: calc(100vh - 162px);
  width: ${props => (props.width && props.width()) || 'calc(100vw - 490px)'};
  overflow-y: auto;
  padding: 1rem 1rem 1.25rem 0.75rem;
  background-color: #ffffff;
`
const L1Text = styled.div`
  cursor: pointer;
  background-color: ${props => (props.focused ? '#ffffff' : 'initial')};
`

const StyledSpan = styled.div`
  cursor: pointer;
  width: 100%;
  ${props => (props.lastElement ? 'padding-bottom: 1rem' : '')};
`

const StyledText = styled(Text)`
  font-size: 13px;
  padding: ${props =>
    props.level === 'L2'
      ? '0.375rem 1rem 0.3rem 1rem'
      : props.level === 'L3'
      ? '0.02rem 1rem'
      : '0.375rem 1rem'};
  display: inline-block;
  width: 100%;
  line-height: ${props => (props.level === 'L2' ? 1.3 : 1.54)};

  &:hover {
    color: #1557bf;
  }
`

class MegaMenu extends Component {
  constructor() {
    super()
    this.state = {
      focused: undefined,
      comingFromOutside: false,
    }
    this.handleFocus = this.handleFocus.bind(this)
    this.createList = this.createList.bind(this)
    this.handleDiagonalMovement = this.handleDiagonalMovement.bind(this)
    this.getDynamicWidth = this.getDynamicWidth.bind(this)
    this.headerClickTracker = this.headerClickTracker.bind(this)
  }

  getDynamicWidth() {
    const L1CONTAINERSIZE = 234
    const CATEGORY = 'categories'

    const { current } = (this.props.accessRef && this.props.accessRef()) || {}
    if (current) {
      const [categoriesNode] = [...current.childNodes].filter(comp => {
        const name =
          comp.dataset && comp.dataset.name && comp.dataset.name.toLowerCase()
        return name === CATEGORY
      })
      const categoryPosition = Math.floor(
        (categoriesNode && categoriesNode.getBoundingClientRect().left) || 0
      )

      if (categoryPosition > 0) {
        return `calc(100vw - ${2 * categoryPosition + L1CONTAINERSIZE}px)`
      }
    }
    return 0
  }

  handleDiagonalMovement(event, index) {
    if (this.moouseMoveEventId) {
      clearTimeout(this.moouseMoveEventId)
    }
    const movementX = event.movementX
    const movementY = event.movementY
    const angle = (Math.atan2(movementY, movementX) * 180) / Math.PI
    if (!((0 < angle && angle < 75) || (0 > angle && -75 < angle))) {
      if (this.state.focused !== index && index !== undefined) {
        if (this.timeoutId) {
          clearInterval(this.timeoutId)
        }
        this.setState({ focused: index, comingFromOutside: false })
      }
    }
  }

  handleFocus(index) {
    if (this.timeoutId) {
      clearInterval(this.timeoutId)
    }

    // update selection with delay use 400 to account transiation delay and duration
    this.timeoutId = setTimeout(
      function () {
        this.setState({ focused: index, comingFromOutside: false })
      }.bind(this, index),
      400
    )
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  headerClickTracker({ e, name, id, level }) {
    e.stopPropagation()
    const info = {
      [GEV.EVENT_CAT]: 'Header_Menu',
      [GEV.EVENT_ACTION]: 'Click',
      [GEV.EVENT_LABEL]: `Category:${id}-${name}`,
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.CATEGORY_CLICKED,
      [GEV.AMPLITUDE_CATEGORY_NAME]: name,
      [GEV.AMPLITUDE_CATEGORY_LEVEL]: level,
      [GEV.AMPLITUDE_CTA_LOCATION]: {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      },
    }
    this.props.track(info)
  }

  createList({
    url,
    name,
    className,
    focused,
    headingColor,
    lastElement,
    id,
    level,
  }) {
    const slug = url.split('/')[2]
    const currentPath = `/category/${slug}`
    const nameToTrack = name ? encodeURIComponent(name) : ''
    return (
      <StyledSpan lastElement={lastElement} className={className} key={url}>
        <Link
          href={`/categoryDetails?slug=${slug}&exclude_ga_track_name=${nameToTrack}&exclude_ga_track_id=${id}`}
          as={currentPath}
          legacyBehavior
        >
          <StyledText
            color={focused || headingColor ? '#1557bf' : '#333333'}
            weight={focused || headingColor ? 'bold' : 'normal'}
            level={className}
            onClick={e => this.headerClickTracker({ e, name, id, level })}
          >
            {name}
          </StyledText>
        </Link>
      </StyledSpan>
    )
  }

  render() {
    const { categoryList } = this.props
    const category = categoryList && categoryList.category
    const length = Array.isArray(category) && category.length
    const { menu } = (category && category[this.state.focused]) || {}
    return length ? (
      <MegaMenuContainer
        onMouseLeave={() => this.handleFocus()}
        onMouseEnter={() => {
          clearInterval(this.timeoutId)
          this.setState({ comingFromOutside: true })
        }}
      >
        <L1Container data-testid="megaMenuL1Container">
          {category.map(({ name, url, id }, index) => {
            const focused = this.state.focused === index
            return (
              <L1Text
                key={url}
                onMouseMove={event => this.handleDiagonalMovement(event, index)}
                focused={focused}
                onMouseOver={() =>
                  this.state.focused !== undefined &&
                  this.state.comingFromOutside &&
                  this.handleFocus(index)
                }
              >
                {this.createList({
                  url,
                  name,
                  className: 'L1',
                  focused,
                  id,
                  level: 1,
                })}
              </L1Text>
            )
          })}
        </L1Container>
        {this.state.focused === undefined || menu === undefined ? (
          ''
        ) : (
          <MegaMenuDescription
            id="megaDescription"
            width={this.getDynamicWidth}
            onMouseEnter={() =>
              this.state.focused !== undefined &&
              this.handleFocus(this.state.focused)
            }
          >
            {menu &&
              menu.map(({ name, url, menu, id }) => {
                return (
                  <div data-testid="megaMenuL2Container" key={name}>
                    {this.createList({
                      url,
                      name,
                      className: 'L2',
                      focused: false,
                      headingColor: true,
                      id,
                      level: 2,
                    })}
                    {menu &&
                      menu.map(({ name, url, id }, index, array) => {
                        const lastElement = index === array.length - 1
                        return (
                          <>
                            {this.createList({
                              url,
                              name,
                              className: 'L3',
                              focused: false,
                              headingColor: false,
                              lastElement,
                              id,
                              level: 3,
                            })}
                          </>
                        )
                      })}
                  </div>
                )
              })}
          </MegaMenuDescription>
        )}
      </MegaMenuContainer>
    ) : (
      ''
    )
  }
}

export default MegaMenu
