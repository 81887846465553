import React from 'react'

function SvgLogoutNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="currentColor" fillRule="evenodd">
        <path d="M7.41 10.744a.833.833 0 101.18 1.179l3.333-3.334a.833.833 0 000-1.178L8.589 4.077a.833.833 0 00-1.178 1.179L10.155 8 7.41 10.744z" />
        <path d="M2.167 2.167h11.666v11.666H2.167v-2.5a.833.833 0 00-1.667 0v3.334c0 .46.373.833.833.833h13.334c.46 0 .833-.373.833-.833V1.333A.833.833 0 0014.667.5H1.333a.833.833 0 00-.833.833v3.334a.833.833 0 101.667 0v-2.5z" />
        <path d="M1.392 8.833h9.882c.493 0 .893-.373.893-.833 0-.46-.4-.833-.893-.833H1.392C.9 7.167.5 7.54.5 8c0 .46.4.833.892.833z" />
      </g>
    </svg>
  )
}

export default SvgLogoutNew
