import React from 'react'

function SvgCartNewActive({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM6 2a1 1 0 01.997.917L7.253 6H21a1 1 0 01.97 1.243l-1.621 6.485A3 3 0 0117.439 16H8.84a3 3 0 01-2.99-2.75L5.08 4H3a1 1 0 110-2z"
      />
    </svg>
  )
}

export default SvgCartNewActive
