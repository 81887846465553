import React from 'react'

function SvgLocationNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      alt=""
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth={1.667}
          d="M10 16.835a21.453 21.453 0 002.73-2.405c1.95-2.044 3.103-4.145 3.103-6.14 0-3.196-2.61-5.79-5.833-5.79-3.223 0-5.833 2.594-5.833 5.79 0 1.995 1.153 4.096 3.103 6.14A21.453 21.453 0 0010 16.835z"
        />
        <circle cx={10} cy={8.333} r={1.667} fill="currentColor" />
      </g>
    </svg>
  )
}

export default SvgLocationNew
