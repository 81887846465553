import React from 'react'

function SvgHelpNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9 .667a8.333 8.333 0 110 16.666A8.333 8.333 0 019 .667zm0 1.666a6.667 6.667 0 100 13.334A6.667 6.667 0 009 2.333zm0 10A.833.833 0 119 14a.833.833 0 010-1.667zM5.716 6.216C6.271 4.685 7.87 3.772 9.5 4.044c1.635.272 2.837 1.66 2.834 3.29 0 1.066-.633 1.909-1.623 2.568a6.64 6.64 0 01-.881.493v.272a.833.833 0 11-1.667 0v-.834c0-.359.23-.677.57-.79a5.003 5.003 0 001.054-.528c.574-.383.88-.79.88-1.183.002-.807-.603-1.505-1.44-1.644-.844-.141-1.664.327-1.942 1.096a.833.833 0 11-1.568-.568z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHelpNew
