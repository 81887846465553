import React from 'react'

const SvgDigitalClub = ({ title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57895 3H12.9474C14.3075 3 16.2984 3.68143 17.9482 5.10738C19.6345 6.56481 21 8.83046 21 12C21 15.1695 19.6345 17.4352 17.9482 18.8926C16.2984 20.3186 14.3075 21 12.9474 21H4V7.5H12.9474C13.5684 7.5 14.5546 7.72224 15.4045 8.39994C16.2972 9.11178 16.9737 10.2754 16.9737 12C16.9737 13.6208 16.2962 14.8543 15.4814 15.6739C15.0774 16.0802 14.6353 16.3901 14.2143 16.6019C13.8105 16.805 13.3613 16.95 12.9474 16.95H7.57895V10.65H9.36842V15.15H12.9474C12.9808 15.15 13.1468 15.1263 13.414 14.9919C13.6641 14.8661 13.9489 14.6698 14.216 14.4011C14.7433 13.8707 15.1842 13.0792 15.1842 12C15.1842 10.8052 14.7422 10.1688 14.2929 9.81051C13.8007 9.41807 13.2211 9.3 12.9474 9.3H5.78947V19.2H12.9474C13.8241 19.2 15.4121 18.7114 16.782 17.5274C18.1155 16.3748 19.2105 14.5905 19.2105 12C19.2105 9.40954 18.1155 7.62519 16.782 6.47262C15.4121 5.28857 13.8241 4.8 12.9474 4.8H9.36842V6.15H7.57895V3Z"
      fill="#333333"
    />
  </svg>
)

export default SvgDigitalClub
