import React from 'react'

function SvgExpandNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path d="M0 16h16V0H0z" />
        <path
          fill="currentColor"
          d="M12.74 5.927a.99.99 0 00-1.308.006L8 9.063l-3.432-3.13a.986.986 0 00-1.308-.006l.058-.053a.786.786 0 00.003 1.191l4.025 3.672c.361.33.94.335 1.308 0l4.025-3.672a.789.789 0 00.003-1.19l.058.052z"
        />
      </g>
    </svg>
  )
}

export default SvgExpandNew
