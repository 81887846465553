import React from 'react'

const Preferences = ({ title, titleId, color, ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}

    <rect
      x="2.18848"
      y="2.1875"
      width="15.625"
      height="15.625"
      rx="1.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5215 12.6042H14.6882"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.31348 12.6042H7.39681"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5215 12.6042C10.5215 13.467 9.82207 14.1666 8.95922 14.1667C8.09636 14.1668 7.39674 13.4675 7.39648 12.6046C7.39623 11.7418 8.09544 11.0421 8.95829 11.0417C9.37281 11.0415 9.77042 11.206 10.0636 11.4991C10.3568 11.7921 10.5215 12.1897 10.5215 12.6042V12.6042Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5635 7.39581H5.31348"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6885 7.39581C14.6885 6.53296 13.9891 5.83344 13.1262 5.83331C12.2634 5.83319 11.5637 6.5325 11.5635 7.39535C11.5632 8.2582 12.2624 8.95793 13.1253 8.95831C13.9883 8.95831 14.6881 8.25887 14.6885 7.39581Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Preferences
