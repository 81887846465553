import styled from 'styled-components'
import CloseIcon from '../icons/CloseIcon'
import { SCREEN_SIZE } from '../../lib/Media'

const styles = {}

styles.StyledTooltipWrapper = styled.div`
  display: block;
`

styles.StyledTooltipBody = styled.div`
  width: 15.5rem;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0 12px 24px 0 rgba(51, 51, 51, 0.24);
  background: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  border: ${props =>
    props.borderColor ? `solid 1px ${props.borderColor}` : 'solid 1px #f2f2f2'};
  border-radius: 3px;
  text-align: left;
`

styles.StyledArrowUp = styled.div`
  position: relative;
  left: ${props => (props.arrowLeft ? props.arrowLeft : '1.5625rem')};
  top: ${props => (props.arrowTop ? props.arrowTop : '0.0625rem')};

  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    box-sizing: border-box;

    border: 0.33rem solid black;
    border-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};

    transform-origin: 0 0;
    transform: rotate(-225deg);

    box-shadow: -1px 1px 3px -1px rgba(51, 51, 51, 0.24);
  }
`

styles.StyledHeaderWrapper = styled.div`
  display: flex;
`

styles.StyledTitleText = styled.div`
  width: 11.9rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  color: ${props => (props.color ? props.color : '#333')};
`

styles.StyledContent = styled.div`
  margin: 0.25rem 0 0.75rem;
  font-size: ${props => (props.fontSize ? props.fontSize : '0.875rem')};
  font-weight: normal;
  line-height: 1.43;
  color: ${props => (props.color ? props.color : '#333')};
`

styles.StyledButton = styled.button`
  padding: 0.5rem 1rem;
  margin-left: auto;
  display: flex;
  box-sizing: border-box;
  background: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  border-radius: 0.25rem;
  border: ${props =>
    props.borderColor ? `solid 1px ${props.borderColor}` : 'solid 1px #1454b8'};
  cursor: pointer;

  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: ${props => (props.color ? props.color : '#1454b8')};
`

styles.StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

styles.StyledInfoIcon = styled.span`
  position: relative;
  margin: 0 0 0 4px;
  cursor: pointer;

  > svg {
    vertical-align: top;
  }
`

styles.StyledJwcIcon = styled.span`
  vertical-align: top;
`

styles.StyledJwcTagWrapper = styled.div`
  margin: 0 0 0.5rem !important;
  ${SCREEN_SIZE.From.MobileL} {
    margin: 0 0 1rem !important;
  }
  ${SCREEN_SIZE.From.Tablet} {
    margin: 0 0 1.5rem !important;
  }
`

styles.StyledJwcTooltipWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: -7rem;
  top: 1.6875rem;
  ${SCREEN_SIZE.From.MobileL} {
    left: -1.5rem;
    top: 1.6875rem;
  }
`

styles.StyledB2bTooltipWrapper = styled.div`
  z-index: 1002;
  position: absolute;
  top: 2rem;
  right: -0.125rem;
`

export default styles
