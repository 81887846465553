import React from 'react'
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'
import Skeleton from '@ntuctech/devex-tangram/Skeleton'
import s from './styles'

function TrendingLoader() {
  return (
    <NdsThemeProvider>
      <s.LoaderContent>
        <s.StyledLoaderHeading>
          <Skeleton
            variant="rectangle"
            width={120}
            height={20}
            data-testid="loader-heading"
          />
        </s.StyledLoaderHeading>

        <s.StyledContent>
          <Skeleton
            variant="rectangle"
            width={100}
            height={36}
            data-testid="loader-content"
          />
          <Skeleton variant="rectangle" width={100} height={36} />
          <Skeleton variant="rectangle" width={100} height={36} />
        </s.StyledContent>

        <s.StyledContent>
          <Skeleton variant="rectangle" width={80} height={36} />
          <Skeleton variant="rectangle" width={80} height={36} />
        </s.StyledContent>
      </s.LoaderContent>
    </NdsThemeProvider>
  )
}

export default TrendingLoader
