import React from 'react'

const SvgRecentSearch = ({ title, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity={0} d="M0 0h24v24H0z" />
      <path
        stroke="#AAA"
        strokeWidth={1.5}
        d="M7.585 18.928a8 8 0 1 0-3.947-5.997"
      />
      <path fill="#AAA" d="M4.518 16.64L.585 13.553 6.38 12z" />
      <path stroke="#AAA" strokeWidth={1.5} d="M11.585 8v5h4" />
    </g>
  </svg>
)

export default SvgRecentSearch
