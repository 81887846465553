import {
  eventsDataset,
  TRACKER_CONSTANTS,
  GTM_ENV_VAR,
} from './campaignTracker'

export default function trackGaLuckyDraw(props) {
  if (props.router?.pathname === '/accounts') {
    const loginStatus = props.isLoggedIn ? 'Login' : 'Guest'
    props.track({
      ...eventsDataset[TRACKER_CONSTANTS.ON_ACCOUNT_LUCKY_DRAW_TAB_CLICK],
      [GTM_ENV_VAR.EVENT_LABEL]: `Luckydraw_account_${loginStatus}`,
    })
  }

  if (props.title === 'luckydraw-tile') {
    props.track({
      ...eventsDataset[TRACKER_CONSTANTS.ON_LUCKY_DRAW_CLICK],
      [GTM_ENV_VAR.EVENT_LABEL]: props.label,
    })
  }
}
