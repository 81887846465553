import { GTM_ENV_VAR, PAGE_TYPES } from '../../lib/eventTrackerFunction'

const TRACKER_CONSTANTS = {
  /* Lucky draw */
  ON_ACCOUNT_LUCKY_DRAW_TAB_CLICK: 'on_account_lucky_draw_tab_click',
  LANDED_LUCKY_DRAW: 'landed_lucky_draw',
  ON_LUCKY_DRAW_CLICK: 'on_lucky_draw_click',
  ON_CONNECT_NOTIFICATION_BANNER_CLICK: 'on_connect_notification_banner_click',
  ON_PAST_TAB_CLICK: 'on_past_tab_click',
  ON_HELP_CLICK: 'on_help_click',

  /* Lucky draw */
}

const CAMPAIGNS_COMMON_ATTRS = {
  [GTM_ENV_VAR.EVENT]: 'hm_push_event',
}

const LUCKY_DRAW_COMMON_ATTRS = {
  ...CAMPAIGNS_COMMON_ATTRS,
  [GTM_ENV_VAR.PAGE_TYPE]: PAGE_TYPES.CAMPAIGN_LUCKY_DRAW,
  [GTM_ENV_VAR.EVENT_CAT]: 'Lucky_draw',
}
const LUCKY_DRAW = 'lucky draw'

const eventsDataset = {
  /* Lucky Draw */
  [TRACKER_CONSTANTS.ON_ACCOUNT_LUCKY_DRAW_TAB_CLICK]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.SCREEN_NAME]: 'omni | account | lucky draw',
  },
  [TRACKER_CONSTANTS.LANDED_LUCKY_DRAW]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Landed',
    [GTM_ENV_VAR.EVENT_LABEL]: 'luckydraw',
    [GTM_ENV_VAR.SCREEN_NAME]: LUCKY_DRAW,
  },
  [TRACKER_CONSTANTS.ON_LUCKY_DRAW_CLICK]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.SCREEN_NAME]: LUCKY_DRAW,
  },
  [TRACKER_CONSTANTS.ON_CONNECT_NOTIFICATION_BANNER_CLICK]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'luckydraw-cardNotifbanner_Connect',
    [GTM_ENV_VAR.SCREEN_NAME]: LUCKY_DRAW,
  },
  [TRACKER_CONSTANTS.ON_PAST_TAB_CLICK]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'luckydraw-past',
    [GTM_ENV_VAR.SCREEN_NAME]: LUCKY_DRAW,
  },
  [TRACKER_CONSTANTS.ON_HELP_CLICK]: {
    ...LUCKY_DRAW_COMMON_ATTRS,
    [GTM_ENV_VAR.EVENT_ACTION]: 'Click',
    [GTM_ENV_VAR.EVENT_LABEL]: 'luckydraw-help',
    [GTM_ENV_VAR.SCREEN_NAME]: LUCKY_DRAW,
  },
  /* Lucky Draw */
}

export { GTM_ENV_VAR, TRACKER_CONSTANTS, eventsDataset }
