import React from 'react'
import { withRouter } from 'next/router'
import styled from 'styled-components'
import PageHeader from '../../components/PageHeader/PageHeader'
import { AccountConsumer } from '../../components/AccountProvider'
import { CheckoutAddressConsumer } from '../../components/CheckoutAddressProvider/CheckoutAddressProvider'

const StyledHeader = styled.header`
  height: auto;
`

const Header = ({
  organizationData,
  categoryList,
  showPostalCodeModule,
  onHandleBackdrop,
  hasNewVouchers,
  hasPaymentAlert,
}) => (
  <CheckoutAddressConsumer>
    {({ checkoutAddress = {} }) => (
      <AccountConsumer>
        {({ isLoggedIn }) => (
          <StyledHeader>
            <PageHeader
              minSpendForFreeDelivery={
                organizationData.config &&
                organizationData.config.order.minimumOrderValueForFreeDelivery
              }
              minSpendForFreePickup={
                organizationData.config &&
                organizationData.config.order.minimumOrderValueForFreePickUp
              }
              organizationData={organizationData}
              isLoggedIn={isLoggedIn}
              checkoutAddress={checkoutAddress}
              categoryList={categoryList}
              showPostalCodeModule={showPostalCodeModule}
              onHandleBackdrop={onHandleBackdrop}
              hasNewVouchers={hasNewVouchers}
              hasPaymentAlert={hasPaymentAlert}
            />
          </StyledHeader>
        )}
      </AccountConsumer>
    )}
  </CheckoutAddressConsumer>
)

export default withRouter(Header)
