import React from 'react'
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'
import Chip from '@ntuctech/devex-tangram/Chip'
import { Router } from '../../routes'
import s from './styles'

function TrendingSearch({
  isSuggestion,
  isTrendingloading,
  data,
  handleSuggestionClick,
  ...props
}) {
  const handleSearch = searchSuggestion => {
    handleSuggestionClick(searchSuggestion, true)
    let nextPageUrl = `/${searchSuggestion?.pageType}/${searchSuggestion?.slug}`
    if (searchSuggestion?.pageType === 'search') {
      nextPageUrl = `/${searchSuggestion?.pageType}?query=${encodeURIComponent(
        searchSuggestion?.slug
      )}`
    }
    Router.pushRoute(nextPageUrl)
  }

  return (
    <NdsThemeProvider>
      <s.StyledTrendWrapper isSuggestion={isSuggestion} {...props}>
        {!isTrendingloading && data.length ? (
          <>
            <s.StyledHeading data-testid="trending-heading">
              Trending
            </s.StyledHeading>
            {data.length &&
              data.map((item, index) => (
                <Chip
                  data-testid="trending-search-item"
                  key={item.name + index}
                  label={item.name}
                  onClick={() => handleSearch(item)}
                />
              ))}
          </>
        ) : (
          ''
        )}
      </s.StyledTrendWrapper>
    </NdsThemeProvider>
  )
}

export default TrendingSearch
