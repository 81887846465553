import React from 'react'

function SvgOrdersNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.5.667c.46 0 .833.373.833.833v15.02a.833.833 0 01-1.229.733l-2.937-1.586-3.858 1.543a.833.833 0 01-.618 0l-3.858-1.543-2.937 1.586a.833.833 0 01-1.23-.733V1.5c0-.46.374-.833.834-.833h15zm-.833 1.666H2.333v12.79l1.708-.923a1.667 1.667 0 011.411-.08L9 15.537l3.548-1.419a1.667 1.667 0 011.41.081l1.709.923V2.333zM9 10.667a.833.833 0 110 1.666H4.833a.833.833 0 110-1.666zm4.167-3.334a.833.833 0 010 1.667H4.833a.833.833 0 110-1.667zm0-3.333a.833.833 0 110 1.667H4.833a.833.833 0 010-1.667z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgOrdersNew
