import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import PropTypes from 'prop-types'
import Text from '../../Text/Text'
import MegaMenu from '../../MegaMenu/megaMenu'
import {
  HEADER_NAVIGATION_B2C,
  HEADER_NAVIGATION_B2B,
  LINKS,
} from '../../../lib/navigation'
import { SCREEN_SIZE, withMedia } from '../../../lib/Media'
import { Router } from '../../../routes'
import { getConfigFor, getExpConfig } from '../../../utils/configService'
import { SPLIT_FEATURES } from '../../../utils/constants'
import { EventTrackingConsumer } from '../../EventTrackingProvider/EventTrackingProvider'
import { GTM_ENV_VAR as GEV } from '../../../lib/eventTrackerFunction'
import { setUserType } from '../../../utils/userType'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../../lib/amplitude'
import { getCtaLocationMeta } from '../../../lib/utils'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { getDesktopNavigationList } from '../navigationUtils'
import { clearDySession } from '../../../utils/DyUtils'

const MenuLinkStyle = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  > a {
    flex: 90%;
  }
  > svg {
    flex: 10%;
    transform: rotate(90deg);
  }
`

const DropdownContent = styled.div`
  min-width: 14.125rem;
  position: absolute;
  background-color: #f3f5f7;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #eaeaea;
  z-index: 1001;
  transition: opacity 0.2s 0.2s, visibility 0.2s 0.2s;
  visibility: hidden;
  opacity: 0;

  > span :hover {
    background-color: #ffffff;
    > div {
      visibility: visible;
      opacity: 1;
    }
    > a {
      font-weight: 600;
      padding: 0.75rem 0.75rem;
      padding-left: 1rem;
    }
  }

  a {
    color: #1557bf;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
    &:not(.swa) {
      text-transform: capitalize;
    }
  }
`

const LinkWrapper = styled.div`
  display: block;
  button {
    cursor: pointer;
  }

  ${SCREEN_SIZE.From.Desktop} {
    &:hover {
      a + ${/* sc-selector */ DropdownContent} {
        visibility: visible;
        opacity: 1;
      }
    }
    display: inherit;
    button {
      outline: none;
    }
    > a {
      padding: 0.85rem 0.1875rem 1.25rem 0;

      ${({ isActive }) =>
        isActive &&
        `
        padding: 0.85rem 0.1875rem 1rem 0;

        > span {
          color: ${props => (props.deliveryModeB2B ? '#FFF' : '#1557bf')};
        }
        & {
          border-bottom: 4px solid #1557bf;
          content: ''
        }
      `}

      &:hover {
        > span {
          color: ${props => (props.deliveryModeB2B ? '#FFF' : '#1557bf')};
        }
      }
    }
  }
`

const StyledAnchor = styled.a`
  color: ${props =>
    props.deliveryModeB2B ? '#FFF' : props.isActive ? '#1557bf' : '#333'};
  text-decoration: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  width: auto;
  font-weight: normal;
`

const HeaderContainer = styled.div`
  display: flex;
  margin-right: 2rem;
`

const StyledUl = styled.ul`
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${SCREEN_SIZE.From.Desktop} {
    flex-direction: row;
    border: none;
    height: auto;
    overflow: visible;

    > li {
      height: 100%;
    }

    > li + li {
      margin-left: 1.5rem;

      ${SCREEN_SIZE.From.Hd} {
        margin-left: 2rem;
      }
    }
  }

  ${SCREEN_SIZE.From.UhdL} {
    margin: auto 0;
  }
`

const StyledNavLabel = styled.span`
  white-space: nowrap;
  font-size: 1rem;
  color: ${props =>
    props.deliveryModeB2B ? '#FFF' : defaultTheme.ColorBaseMineShaft700};
  box-sizing: border-box;
  text-align: center;
`

const StyledLink = styled.a`
  padding: 0.375rem 1rem !important;
  color: #333333 !important;
  display: flex;

  text-transform: none;
  font-size: 0.8125rem;

  &:hover {
    color: ${props => (props.deliveryModeB2B ? '#FFF' : '#1557bf !important')};
  }
`

const giftingEvents = {
  [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.EGIFTING_MAIN_CLICKED,
  [GEV.AMPLITUDE_CTA_LOCATION]: 'home grocery-online',
  [GEV.AMPLITUDE_CTA_SUBLOCATION]: 'navigation more',
  [GEV.EVENT_CAT]: 'egifting voucher',
  [GEV.EVENT_ACTION]: 'click',
  [GEV.EVENT_LABEL]: 'gifting_more',
  [GEV.EVENT]: 'hm_push_event',
  [GEV.ASSET_TYPE]: 'e-gifting',
}

class NavigationList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeNavPath: undefined,
      activeNavLabel: undefined,
    }

    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.accessRef = this.accessRef.bind(this)
    this.componentRef = React.createRef()
    this.handleIdentify = this.handleIdentify.bind(this)
  }

  accessRef() {
    return this.componentRef
  }

  handleShowMenu() {
    this.refr.style.display = 'inherit'
  }

  componentDidMount() {
    if (this.props.router) {
      const currentPath = this.props.router?.pathname.split('/')[1]
      const currentAsPath = this.props.router?.asPath?.split('/')[1]
      const secondaryMatchers = {
        categoryDetails: 'Categories',
        categories: 'Categories',
        category: 'Categories',
      }
      this.setState({
        activeNavPath: currentPath,
        activeNavAsPath: currentAsPath,
        activeNavLabel: secondaryMatchers[currentPath],
      })
    }
  }

  handleOnClick(e, trackingLabel, href) {
    const { handleClickTracking } = this.props
    e.preventDefault()
    if (href === '#') {
      return
    }
    handleClickTracking(trackingLabel)
    if (trackingLabel === 'Weekly promotions') {
      Router.pushRoute('/weekly-promotions')
    } else {
      Router.pushRoute(href)
    }
  }

  handleIdentify(mode) {
    clearDySession()
    // Identify calls goes here
    const { isEnabled, configuration } =
      getConfigFor(SPLIT_FEATURES.FE_SEGMENT_IDENTIFY_CALLS) || {}
    const isIdentifySegmentEnabled = isEnabled && configuration?.is_segment_on
    if (isIdentifySegmentEnabled && this.props?.callSetIdentify) {
      this.props?.callSetIdentify(mode)
    }
  }

  render() {
    const {
      className,
      categoryList,
      onHandleBackdrop,
      deliveryModeB2B,
      isLoggedIn,
      track,
    } = this.props
    const { activeNavPath, activeNavAsPath, activeNavLabel } = this.state

    const headerLinks = deliveryModeB2B
      ? HEADER_NAVIGATION_B2B
      : HEADER_NAVIGATION_B2C
    const isGiftingDomainEnabled =
      getExpConfig(SPLIT_FEATURES.GIFT_ENTRY_POINT)?.treatment === 'on'
    return (
      <nav className={className} style={{ flex: '1 1 0' }}>
        <HeaderContainer>
          <StyledUl ref={this.componentRef}>
            {getDesktopNavigationList().map(({ label, url }, index) => {
              const isActive =
                (activeNavPath && activeNavPath === url.split('/')[1]) ||
                (activeNavAsPath && activeNavAsPath === url.split('/')[1]) ||
                (activeNavLabel && activeNavLabel === label)
              return (
                <li key={label + index} data-name={label}>
                  <LinkWrapper
                    deliveryModeB2B={deliveryModeB2B}
                    onClick={this.handleShowMenu}
                    onMouseEnter={() => {
                      if (
                        (label === 'Categories' || label === 'More') &&
                        onHandleBackdrop
                      ) {
                        onHandleBackdrop(true)
                        if (label === 'More') {
                          track({
                            [GEV.EVENT_CAT]: 'PromotionFunnel',
                            [GEV.EVENT_ACTION]: 'PromotionImpressions',
                            [GEV.EVENT_LABEL]: 'Menu_More_Text Link',
                            [GEV.EVENT]: 'promoImpression',
                          })
                        } else {
                          track({
                            [GEV.EVENT_CAT]: 'Header_Menu',
                            [GEV.EVENT_ACTION]: 'Hover',
                            [GEV.EVENT_LABEL]: 'Category',
                          })
                        }
                      }
                    }}
                    onMouseLeave={() =>
                      (label === 'Categories' || label === 'More') &&
                      onHandleBackdrop &&
                      onHandleBackdrop(false)
                    }
                    isActive={isActive}
                  >
                    <Link href={url} passHref prefetch={false} legacyBehavior>
                      <StyledAnchor
                        deliveryModeB2B={deliveryModeB2B}
                        onClick={e => this.handleOnClick(e, label, url)}
                        title={label}
                        data-testid={label}
                        target={label === 'Help' ? '_blank' : ''}
                      >
                        <StyledNavLabel deliveryModeB2B={deliveryModeB2B}>
                          {label}
                        </StyledNavLabel>
                      </StyledAnchor>
                    </Link>
                    {label === 'Categories' && (
                      <DropdownContent
                        ref={node => {
                          this.refr = node
                        }}
                      >
                        <MegaMenu
                          categoryList={categoryList}
                          accessRef={this.accessRef}
                          track={track}
                        />
                      </DropdownContent>
                    )}
                    {label === 'More' && (
                      <DropdownContent>
                        {headerLinks &&
                          headerLinks.map((link, i) => {
                            if (
                              !isGiftingDomainEnabled &&
                              link.url === LINKS.sendEGiftVoucher.url
                            ) {
                              return null
                            }
                            return (
                              <MenuLinkStyle key={link.label + i}>
                                <Link
                                  href={link.url}
                                  passHref
                                  prefetch={false}
                                  legacyBehavior
                                >
                                  <StyledLink
                                    deliveryModeB2B={deliveryModeB2B}
                                    className={
                                      link === LINKS.storeWeeklyAds ? 'swa' : ''
                                    }
                                    target={link.isNewTab ? '_blank' : ''}
                                    rel="noopener noreferrer"
                                    onClick={e => {
                                      if (link === LINKS.shopForBusiness) {
                                        this.handleIdentify('b2b')
                                        setUserType(
                                          'B2B',
                                          isLoggedIn,
                                          true,
                                          null
                                        )
                                      } else if (
                                        link === LINKS.shopForPersonal
                                      ) {
                                        this.handleIdentify('b2c')
                                        setUserType(
                                          'B2C',
                                          isLoggedIn,
                                          true,
                                          null
                                        )
                                      } else if (
                                        link === LINKS.sendEGiftVoucher
                                      ) {
                                        this.props.track(giftingEvents)
                                      } else {
                                        this.handleOnClick(
                                          e,
                                          link.label,
                                          link.url
                                        )
                                        link === LINKS.storeWeeklyAds
                                        const amplitudeEventProps =
                                          link === LINKS.storeWeeklyAds
                                            ? {
                                                [GEV.AMPLITUDE_EVENT_NAME]:
                                                  AMP_EVENT_NAME.STORE_WEEKLY_ADS_CLICKED,
                                                [GEV.AMPLITUDE_CTA_SUBLOCATION]:
                                                  ['more', 'store weekly ads'],
                                                [GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE]:
                                                  ['navigation'],
                                                [GEV.AMPLITUDE_CTA_LOCATION]: {
                                                  pageName: getPageTypeByUrl(),
                                                  pageMeta:
                                                    getCtaLocationMeta() || [],
                                                },
                                              }
                                            : {}
                                        this.props.track({
                                          [GEV.EVENT_CAT]: 'PromotionFunnel',
                                          [GEV.EVENT_ACTION]: 'PromotionClick',
                                          [GEV.EVENT_LABEL]: `Menu_More_Text Link - ${link.label}`,
                                          [GEV.EVENT]: 'promotionClick',
                                          ...amplitudeEventProps,
                                        })
                                      }
                                    }}
                                  >
                                    <Text>{link.label}</Text>
                                  </StyledLink>
                                </Link>
                              </MenuLinkStyle>
                            )
                          })}
                      </DropdownContent>
                    )}
                  </LinkWrapper>
                </li>
              )
            })}
          </StyledUl>
        </HeaderContainer>
      </nav>
    )
  }
}

NavigationList.defaultProps = {
  handleClickTracking: PropTypes.func.isRequired,
}

const NavigationListWrapper = props => (
  <EventTrackingConsumer>
    {({ track }) => <NavigationList {...props} track={track} />}
  </EventTrackingConsumer>
)

export default withMedia(NavigationListWrapper)
