import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import nookies from 'nookies'
import deepEqual from 'fast-deep-equal'
import S from '../StoreSelectorTooltip/styles'
export const B2B_TOOLTIP = 'b2bToolTip'
class B2bTooltip extends Component {
  constructor(props) {
    super(props)
    const b2bTooltipConfig = {
      'tooltipHeader': 'Add company details',
      'tooltipBody':
        'Enjoy a faster checkout when you add your company details',
      'tooltipCta': 'Add now',
    }
    let { [B2B_TOOLTIP]: b2bTooltip } = nookies.get()
    b2bTooltip = !isEmpty(b2bTooltip) ? JSON.parse(b2bTooltip) : {}
    if (!b2bTooltip.opened) {
      const options = {
        path: '/',
        maxAge: 365 * 24 * 60 * 60, // one year
      }
      nookies.set({}, B2B_TOOLTIP, JSON.stringify({ opened: true }), options)
    } else if (b2bTooltip.opened && !b2bTooltip.seen) {
      const options = {
        path: '/',
        maxAge: 365 * 24 * 60 * 60, // one year
      }
      nookies.set(
        {},
        B2B_TOOLTIP,
        JSON.stringify({ opened: true, seen: true }),
        options
      )
    }

    this.state = {
      showTooltip: !b2bTooltip?.opened && !isEmpty(b2bTooltipConfig),
      b2bTooltipConfig,
    }

    this.closeTooltip = this.closeTooltip.bind(this)
    this.handleCtaClick = this.handleCtaClick.bind(this)
  }

  closeTooltip = () => {
    this.props.updateB2bTooltipState(true, true)
    this.setState({ showTooltip: false })
    const options = {
      path: '/',
      maxAge: 365 * 24 * 60 * 60, // one year
    }
    nookies.set(
      {},
      B2B_TOOLTIP,
      JSON.stringify({ opened: true, seen: true }),
      options
    )
  }

  handleCtaClick() {
    this.props.showBusinessProfilePopupState()
    this.closeTooltip()
  }

  componentDidUpdate(prevProps) {
    let { [B2B_TOOLTIP]: b2bTooltip } = nookies.get()
    b2bTooltip = !isEmpty(b2bTooltip) ? JSON.parse(b2bTooltip) : {}
    if (this.state.isSeenB2bTooltip !== b2bTooltip?.seen) {
      if (!deepEqual(prevProps.router, this.props.router)) {
        if (b2bTooltip?.opened && !b2bTooltip?.seen) {
          this.closeTooltip()
        }
      }
    }
  }

  componentWillUnmount() {
    this.closeTooltip()
  }

  render() {
    const { b2bTooltipConfig, showTooltip } = this.state
    if (!showTooltip) {
      return null
    }

    return (
      <S.StyledB2bTooltipWrapper data-testid="b2b-tooltip-wrapper">
        <S.StyledTooltipWrapper>
          <S.StyledArrowUp arrowLeft="15rem" arrowTop="0.0625rem" />
          <S.StyledTooltipBody>
            <S.StyledHeaderWrapper>
              <S.StyledTitleText>
                {b2bTooltipConfig?.tooltipHeader}
              </S.StyledTitleText>
              <S.StyledCloseIcon
                data-testid="b2b-tooltip-close"
                width="1.5rem"
                height="1.5rem"
                onClick={() => this.closeTooltip()}
              />
            </S.StyledHeaderWrapper>
            <S.StyledContent fontSize="1rem">
              {b2bTooltipConfig?.tooltipBody}
            </S.StyledContent>
            <S.StyledButton
              data-testid="b2b-tooltip-cta"
              onClick={this.handleCtaClick}
            >
              {b2bTooltipConfig?.tooltipCta}
            </S.StyledButton>
          </S.StyledTooltipBody>
        </S.StyledTooltipWrapper>
      </S.StyledB2bTooltipWrapper>
    )
  }
}

B2bTooltip.defaultProps = {}
export default B2bTooltip
