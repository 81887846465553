/* eslint-disable react/jsx-handler-names */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import getConfig from 'next/config'
import {
  HEADER_NAVIGATION_B2C,
  HEADER_NAVIGATION_B2B,
  MOBILE_NAVIGATION,
  LINKS,
  ACCOUNTS_MENU,
} from './../../../lib/navigation'
import { GTM_ENV_VAR as GEV } from './../../../lib/eventTrackerFunction'
import { get, headers } from './../../../lib/fetch'
import { SCREEN_SIZE } from './../../../lib/Media'
import LogoutIcon from '../../icons/LogoutNew'
import TextIcon from './../../TextIcon'
import MobileNavCloseIcon from '../../icons/MobileNavClose'
import { StyledPopup } from '../../Popup/StyledPopup'
import Text from '../../Text'
import { disablePageScroll } from '../../../lib/utils'
import { getExpConfig } from '../../../utils/configService'
import { SPLIT_FEATURES } from '../../../utils/constants'
import { CONNECTION_STATUSES } from '../../../utils/constants/plusStatuses'
import { setUserType } from '../../../utils/userType'
import { Router } from '../../../routes'
import { AccountConsumer } from '../../AccountProvider'
import { CheckoutAddressConsumer } from '../../CheckoutAddressProvider/CheckoutAddressProvider'
import getBusinessProfileState from '../../../utils/getBusinessProfileState'
import { AMP_EVENT_NAME } from '../../../lib/amplitude'
import { getMobilePrimaryNavigation } from '../navigationUtils'

const {
  publicRuntimeConfig: { STRUDEL_API },
} = getConfig()

const StyledNavContainer = styled.nav`
  width: 64%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  z-index: 10057;
  top: 0;
  left: ${props => (props.show ? '0' : '-64%')};
  transition: left 0.5s ease;
  ${SCREEN_SIZE.Only.Desktop} {
    display: none;
  }
  ${SCREEN_SIZE.From.Tablet} {
    width: 45%;
    left: ${props => (props.show ? '0' : '-45%')};
  }
`

const StyledNavContent = styled.div`
  height: 100%;
  padding: 1rem 0 0 1rem;
  box-sizing: border-box;
`

const StyledNavClose = styled(MobileNavCloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 1.5rem;
`

const StyledNavLinkText = styled.a`
  text-decoration: none;
  color: #333;
  height: 100%;
  display: block;

  ${({ isActive }) =>
    isActive &&
    `
    color: #1557bf;
    border-left: 4px solid #1557bf;
    margin-left: -16px;
    padding-left: 12px;

    svg {
      color: #1557bf;
    }
  `}

  ${({ hasAlert }) =>
    hasAlert &&
    css`
      :after {
        content: '';
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;
        background-color: #c10b3a;
        border-radius: 50%;
        margin-left: 0.25rem;
      }
    `}

  ${({ indentLeft, isActive }) =>
    indentLeft && `padding-left: ${isActive ? '2.5rem' : '1.75rem'};`}
`

const StyledNavLinkTextIcon = styled(TextIcon)`
  text-decoration: none;
  color: #333;

  svg {
    color: #696969 !important;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: #1557bf;
    border-left: 4px solid #1557bf;
    margin-left: -16px;
    padding-left: 12px;

    svg {
      color: #1557bf !important;
    }
  `}
`

const StyledUl = styled.ul`
  height: 100%;

  > li {
    height: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
  }

  > li:last-child {
    margin-bottom: 0;
  }
`

const StyledNavDivider = styled.li`
  border-color: rgba(51, 51, 51, 0.1);
  border-style: solid;
  margin: 0 1em 1rem 0 !important;
  height: 0.5rem !important;
  border-width: 0 0 1px 0;
  box-sizing: content-box;
`

const StyledNavItem = styled.li`
  cursor: pointer;
`

class MobileNavigationInner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeNavPath: undefined,
      activeNavLabel: undefined,
      navProfile: MOBILE_NAVIGATION.navProfile,
      isMounted: false
    }

    this.componentRef = React.createRef()
    this.renderNav = this.renderNav.bind(this)
    this.renderLogoutLink = this.renderLogoutLink.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
  }

  handleTouchEnd(e) {
    if (
      e &&
      e.target.dataset?.testid?.toString().includes('mobileNavOverlay')
    ) {
      e.stopPropagation()
      disablePageScroll(false)
      this.props.handleClose && this.props.handleClose()
    }
  }

  handleLinkClick = label => e => {
    const { isLoggedIn, handleClickTracking } = this.props
    handleClickTracking(label)
    if (label === 'Login/Sign up') {
      this.props.handleLogin(e)
    }
    if (label === LINKS.shopForBusiness.label) {
      setUserType('B2B', isLoggedIn, true, null)
    } else if (label === LINKS.shopForPersonal.label) {
      setUserType('B2C', isLoggedIn, true, null)
    } else if (label === LINKS.sendEGiftVoucher.label) {
      this.props.track({
        [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.EGIFTING_MAIN_CLICKED,
        [GEV.AMPLITUDE_CTA_LOCATION]: 'grocery-online home',
        [GEV.EVENT_CAT]: 'egifting voucher',
        [GEV.EVENT_ACTION]: 'click',
        [GEV.EVENT_LABEL]: 'gifting_leftnav',
        [GEV.EVENT]: 'hm_push_event',
        [GEV.ASSET_TYPE]: 'e-gifting',
      })
    } else if (label === 'Weekly promotions') {
      Router.pushRoute('/weekly-promotions')
    }
    const accountLabels = Object.values(ACCOUNTS_MENU)

    const shouldCloseMenu =
      accountLabels.some(accLabel => label.includes(accLabel)) &&
      window.location.pathname === '/accounts'

    if (shouldCloseMenu) {
      this.props.handleClose()
    }
  }

  async fetchPlusStatus() {
    try {
      const response = await get(
        `${STRUDEL_API}/me/profile?fields=connectionStatus`,
        {
          headers: headers(),
        }
      )

      const hasPlusAlert = [
        CONNECTION_STATUSES.legacyPartial,
        CONNECTION_STATUSES.legacyFull,
      ].some(status => status === response.connectionStatus)

      return this.props.updatePlusAlert(hasPlusAlert)
    } catch {
      // something went wrong should do nothing!
      return null
    }
  }

  componentDidUpdate(prevProps) {
    const drawer = document.getElementById('drawer')
    disablePageScroll(this.props.navShow)
    if (drawer !== null) {
      disablePageScroll(true)
    }

    if (prevProps.navShow !== this.props.navShow && this.props.navShow) {
      this.fetchPlusStatus()
    }
  }

  componentDidMount() {
    const drawer = document.getElementById('drawer')
    disablePageScroll(this.props.navShow)
    if (drawer !== null) {
      disablePageScroll(true)
    }
    window.addEventListener('click', this.handleTouchEnd, false)
    if (this.props.router) {
      const currentPath = this.props.router?.pathname.split('/')[1]
      const currentAsPath = this.props.router?.asPath?.split('/')[1]
      const secondaryMatchers = {
        categoryDetails: 'Categories',
        categories: 'Categories',
        category: 'Categories',
      }
      this.setState({
        activeNavPath: currentPath,
        activeAsPath: currentAsPath,
        activeNavLabel: secondaryMatchers[currentPath],
      })
    }

    if (this.props.isLoggedIn) {
      const [profileObj, ...rest] = MOBILE_NAVIGATION.navProfile
      const navWithAccountsMenu = [
        profileObj,
        ...MOBILE_NAVIGATION.accountsMenu,
        ...rest,
      ]

      this.setState({
        navProfile: navWithAccountsMenu,
      })
    }

		this.setState({
			isMounted: true,
		});
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleTouchEnd, false)
  }

  handleAlertStatus(props, label = '') {
    const labelsWithAlert = [
      {
        label: ACCOUNTS_MENU.plus,
        hasAlert: props.hasPlusAlert || false,
      },
      {
        label: ACCOUNTS_MENU.vouchers,
        hasAlert: props.hasNewVouchers,
      },
      {
        label: ACCOUNTS_MENU.payment,
        hasAlert: props.hasPaymentAlert,
      },
    ]

    const labelHasAlert = labelsWithAlert.find(l => l.label === label)

    return labelHasAlert?.hasAlert
  }

  renderNav(navList, { indentLeft = false } = {}) {
    const { activeNavPath, activeAsPath, activeNavLabel } = this.state
    const { isLoggedIn } = this.props
    return navList.map((link, index) => {
      const { label, secondaryText, url, Icon } = link

      const isGiftingDomainEnabled =
        getExpConfig(SPLIT_FEATURES.GIFT_ENTRY_POINT)?.treatment === 'on'
      if (
        !this.props.canUserAccessBusinessProfile &&
        url === '/accounts?tab=business'
      ) {
        return null
      }

      if (!isLoggedIn && url === '/accounts?tab=preferences') {
        return null
      }

      if (!isLoggedIn && url === '/accounts?tab=vouchers') {
        return null
      }

      if (!isLoggedIn && url === '/accounts?tab=campaigns') {
        return null
      }

      if (!isGiftingDomainEnabled && url === LINKS.sendEGiftVoucher.url) {
        return null
      }

      let labelToDisplay = label
      let isAccountChild = false

      if (typeof window !== 'undefined' && window?.location?.hash) {
        isAccountChild = window.location.hash
          .toUpperCase()
          .includes(label.toUpperCase())
      }

      const isActive =
        isAccountChild ||
        (activeNavPath &&
          activeNavPath === url.split('/')[1] &&
          window.location.hash === '') ||
        (activeAsPath && activeAsPath === url.split('/')[1]) ||
        (activeNavLabel && activeNavLabel === labelToDisplay)

      if (!isLoggedIn && label === 'Profile') {
        labelToDisplay = secondaryText
      }

      const hasAlert = this.handleAlertStatus(this.props, label)
      return (
        <li key={labelToDisplay + index} data-name={labelToDisplay}>
          <Link href={url} passHref legacyBehavior>
            {Icon ? (
              <StyledNavLinkText
                hasAlert={hasAlert}
                href={url}
                onClick={this.handleLinkClick(labelToDisplay)}
              >
                <StyledNavLinkTextIcon
                  prefix={<Icon color={isActive ? '#1557bf' : '#696969'} />}
                  isActive={isActive}
                >
                  {labelToDisplay}
                </StyledNavLinkTextIcon>
              </StyledNavLinkText>
            ) : (
              <StyledNavLinkText
                hasAlert={hasAlert}
                indentLeft={indentLeft}
                isActive={isActive}
                onClick={this.handleLinkClick(labelToDisplay)}
              >
                <Text>{labelToDisplay}</Text>
              </StyledNavLinkText>
            )}
          </Link>
        </li>
      )
    })
  }

  renderLogoutLink() {
    return (
      <StyledNavItem>
        <StyledNavLinkText
          onClick={e => this.props.handleLogout(e)}
          data-testid="logoutButton"
        >
          <StyledNavLinkTextIcon
            prefix={<LogoutIcon width="1.25rem" height="1.25rem" />}
          >
            Logout
          </StyledNavLinkTextIcon>
        </StyledNavLinkText>
      </StyledNavItem>
    )
  }

  render() {
    const { navShow, handleClose, isLoggedIn, isBoysBrigade, deliveryModeB2B } =
      this.props
    const headerLinks = deliveryModeB2B
      ? HEADER_NAVIGATION_B2B
      : HEADER_NAVIGATION_B2C
    return (
      <React.Fragment>
        {navShow && disablePageScroll(true)}
        {navShow && <StyledPopup data-testid="mobileNavOverlay" />}
        <StyledNavContainer ref={this.componentRef} show={navShow}>
          <StyledNavContent>
            <StyledNavClose onClick={handleClose} />
            <StyledUl>
              {!isBoysBrigade && (
                <React.Fragment>
                  {this.renderNav(getMobilePrimaryNavigation())}
                </React.Fragment>
              )}
							{this.state.isMounted && (
								<React.Fragment>
									<StyledNavDivider />
									{this.renderNav(this.state.navProfile, {
										indentLeft: true,
									})}
								</React.Fragment>
							)}
              {!isBoysBrigade && (
                <React.Fragment>
                  <StyledNavDivider />
                  {this.renderNav(
                    headerLinks.filter(item => item !== LINKS.digitalClub)
                  )}
                </React.Fragment>
              )}
              {this.state.isMounted && isLoggedIn && (
                <React.Fragment>
                  <StyledNavDivider />
                  {this.renderLogoutLink()}
                </React.Fragment>
              )}
            </StyledUl>
          </StyledNavContent>
        </StyledNavContainer>
      </React.Fragment>
    )
  }
}

const MobileNavigation = props => (
  <AccountConsumer>
    {({ accountData }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => {
          const { canUserAccessBusinessProfile } = getBusinessProfileState({
            accountData,
            checkoutAddress,
          })

          return (
            <MobileNavigationInner
              {...props}
              canUserAccessBusinessProfile={canUserAccessBusinessProfile}
            />
          )
        }}
      </CheckoutAddressConsumer>
    )}
  </AccountConsumer>
)

MobileNavigation.defaultProps = {
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isBoysBrigade: PropTypes.bool.isRequired,
}

export default MobileNavigation
