import React from 'react'

function SvgSearchNew({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="translate(2 2)"
      >
        <circle cx={5} cy={5} r={5} />
        <path d="M12 12L9 9" />
      </g>
    </svg>
  )
}

export default SvgSearchNew
